import React, { useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SwiperCore, { Keyboard, Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Seo from '../components/seo/seo';

interface DataProps {
  locales: any;
  allFile: any;
}

const GalleryPage = ({ data }: PageProps<DataProps>) => {
  const [mainSwiper, setMainSwiper] = useState<SwiperCore>();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();

  const slides = data.allFile.nodes.map((node: any) => (
    <SwiperSlide key={node.id}>
      <GatsbyImage image={getImage(node)!} alt={node.id} />
    </SwiperSlide>
  ));

  return (
    <Layout page="gallery">
      <Seo title="Galéria" />
      <div className="gallery container-fluid">
        <div className="page-content centered">
          <h1 className="page-title"><Trans>gallery</Trans></h1>

          <div className="gallery-content">
            <Swiper
              className="main-swiper"
              modules={[Keyboard, Navigation, Pagination, Thumbs]}
              navigation={true}
              pagination={{ type: "fraction" }}
              keyboard={{ enabled: true }}
              thumbs={{ swiper: thumbsSwiper }}
              spaceBetween={0}
              slidesPerView={1}
              loop={true}
              onSwiper={setMainSwiper}>
              {slides}
            </Swiper>
            <Swiper
              id="thumbs"
              slidesPerView={6}
              watchSlidesProgress={true}
              onSwiper={setThumbsSwiper}>
              {slides}
            </Swiper>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GalleryPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  }
`;